import '../styles/StringQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import Select from 'react-dropdown-select';
import { CloseButton, Col, Form, Row } from 'react-bootstrap';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { getLocalization } from 'global/global';
import { checkValidationRuleError } from '../utils/utils';
import { StringQuestionProps } from '../Containers/StringQuestionContainer';
import TextInputComponent from './TextInputComponent';
import TextAreaComponent from './TextAreaComponent';
import QuestionLabel from './QuestionLabel';
import { Elements } from './index';

interface State {
  value: string;
  edit: boolean;
  itemList: string[] | null;
  validationList: JSONInterface[];
  error: boolean;
  hasError: boolean;
  errorText?: string;
  placeholder: string;
}

export default class StringQuestion extends React.Component <StringQuestionProps, State> {
  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    const { question, edit, dataPoint } = this.props;

    const validationList = question.validatefield && question.validationlist ? this.getValidationList() : [];
    let value = dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '';
    if (value && question.validatefield && question.validationlist) {
      const newValue = validationList.find(v => {
        if (v.value.indexOf(' ') > 0 &&  v.value.replaceAll(' ', '') === value.trim()) {
          return true;
        }
        return false;
      });
      if (newValue) {
        value = newValue.value;
        this.props.updateAnswer({ [question.id]: value });
      }
    }
    this.state = {
      value: value,
      edit: edit,
      itemList: [],
      validationList,
      error: false,
      hasError: false,
      placeholder: ''
    };
  }

  @bind
  private getValidationList() {
    return this.props.question.validationlist.split(';').map(s => {
      if (s.trim() !== '') {
        return { label: s.trim(), value: s.trim() };
      } else {
        return null;
      }
    }).filter(s => s !== null);
  }

  @bind
  private handleChange(value: string) {
    const { question, updateAnswer } = this.props;
    let error = false;
    if (question.regexp) {
      let regex = question.regexp;
      if (regex.indexOf('/') !== -1) {
        regex = regex.substring(regex.indexOf('/') + 1, regex.lastIndexOf('/'));
      }
      const valid = new RegExp(regex).test(value);
      if (!valid) {
        error = true;
        this.setState({ error });
        return;
      }
    }

    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
    this.setState({ value, error });
  }

  /* @bind
  private doneTyping(value: any) {
    const { question } = this.props;

    const inputLength = value.length;
    const vlist = question.validationlist.split(';');
    const newList = inputLength === 0 ? vlist :  filter(vlist, ((val) =>
      val.toLowerCase().search(value.toLowerCase()) !== -1
    ));
    let itemList;
    if (newList.length < 100) {
      itemList = map(newList, ((item, index) => (
        <li
          key={`${question.id}_${ index }`}
          className="list-item"
          onClick={() => this.itemSelected(item)}
        >
         {item}
        </li>
      )));
    }
    this.setState({ itemList, value });
  }
*/
  @bind
  private doneTypingWithValidity(value: any, valid = true) {
    const { question, updateAnswer } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      this.setState({ value, error: !valid}, () => updateAnswer(newAns));
    } else {
      this.setState({ value, error: !valid});
    }
  }

  @bind
  private renderIncludeFile(): JSX.Element[] | undefined {
    const { question, dataPoint, updateAnswer, formUtils } = this.props;
    if (question.includeFile || formUtils.getModel().childUI) {
      return [(
        <Elements.FileQuestion
          key={`include-file-string-question-${question.id}-${dataPoint.id}`}
          accept={question.accept}
          question={question}
          dataPoint={dataPoint}
          updateAnswer={updateAnswer}
          edit={formUtils.getModel().childUI ? false : this.props.edit}
        />
      )];
      /* const types = question.accept.split(',');
      const fileInputs = types.map(type => {
        return (
          <Elements.FileQuestion
            key={`${question.id}_${type}`}
            accept={type}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
            edit={this.props.edit}
          />
        );
      });
      return fileInputs;*/
    }
    return undefined;
  }

  @bind
  private renderIncludeImage(): JSX.Element | undefined {
    const { question, dataPoint, updateAnswer } = this.props;
    if (question.includeImage) {
      return (
        <Elements.FileQuestion
          question={question}
          dataPoint={dataPoint}
          updateAnswer={updateAnswer}
          accept={'image/*'}
          edit={this.props.edit}
        />
      );
    }
    return undefined;
  }

  @bind
  private itemSelected(item: string) {
    const { updateAnswer, question } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = item;
      this.setState({ value : item, itemList : [] }, () => updateAnswer(newAns));
    } else {
      this.setState({ value : item, itemList : [] });
    }
  }

  @bind
  private renderStringInput(): JSX.Element {
    const { value, error, hasError, errorText, placeholder } = this.state;
    const { question, formUtils, dataPoint } = this.props;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    let salesForceURL;
    if (question.salesForceURL && question.salesForceId && value && value !== '') {
      salesForceURL = (
        <a href={question.salesForceURL + '/' + value} target="_blank" rel="noreferrer">
          Sales force {value}
        </a>
      );
    }
    let hasErrorSpan;
    if (error) {
      const errorMsg = question.triggerValues.triggerValue[0].action.notification.text;
      hasErrorSpan = (<span className="regexperror">{errorMsg}</span>);
    }
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '') || hasError ? 'is-invalid' : '';
    const className =
      `${!horizontal ? formUtils.getResponsiveView(question) : 'container-fluid'}`;
    const input = (
      <TextAreaComponent
        name={question.id}
        readOnly={!this.state.edit}
        value={value}
        placeholder={placeholder}
        onChange={this.handleChange}
        extraClass={hasErrorClass}
      />
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={className}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10}>
            {input}
          </Col>
        ) : input}
        {hasErrorSpan}
        {salesForceURL}
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
        {hasError && errorText && (
          <div className="invalid-value-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }

  @bind
  private async onCreateNew(newItem: JSONInterface) {
    const { question, formUtils } = this.props;
    const { validationList } = this.state;
    const added = await this.props.addNewValidationListItem(formUtils.getModel().ref, question.id, newItem.value);
    if (added) {
      this.setState({ validationList: [...validationList, newItem ] });
      formUtils.updateValidationList(question.id, newItem.value);
    }
    console.log(added);
  }

  @bind
  private async onRemoveItem(item: JSONInterface) {
    const { question, formUtils } = this.props;
    const { validationList, value } = this.state;
    const added = await this.props.removeValidationListItem(formUtils.getModel().ref, question.id, item.value);
    if (added) {
      const newValue = value.split(',').filter(v => v !== item.label).join(',');
      this.setState({ validationList: validationList.filter(l => l.label !== item.label), value: newValue });
      formUtils.removeValidationListItem(question.id, item.value);
    }
    console.log(added);
  }

  @bind
  private renderValidationInput(): JSX.Element {
    const { value, hasError, errorText, validationList, edit } = this.state;
    const { question, formUtils, dataPoint, clientPersist } = this.props;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const canAdd = clientPersist.roles.indexOf('enumerator') === -1;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const className =
      `${this.state.hasError ? 'has-error' : ''}
      ${!horizontal ? formUtils.getResponsiveView(question) : 'container-fluid'}`;
    const hasErrorClass = hasError ? 'is-invalid' : '';
    const selected = value.split(',');
    const val = validationList.filter(v => selected.indexOf(v.value.trim()) > -1);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
      return (
        <div className="validation-list-item">
          <span onClick={() => methods.addItem(item)}>{item.label}</span>
          <CloseButton
            className="float-end"
            onClick={() => {
              const confirmed = confirm(getLocalization('removeValidationListItem'));
              if (confirmed) {
                void this.onRemoveItem(item);
              }
            }}
          />
        </div>
      );
    };

    const input = (
      <Select
        multi={question.multiChoiceValidationList === true}
        options={validationList}
        onChange={(vx) => this.itemSelected(vx.length > 0 ? vx.map( v => v.value.trim()).join(',') : '')}
        values={val}
        searchable
        clearable
        className={hasErrorClass}
        placeholder={''}
        disabled={!edit}
        create={canAdd}
        createNewLabel={`${getLocalization('addNew')} ${question.text}`}
        onCreateNew={(newItem) => void this.onCreateNew(newItem)}
        itemRenderer={customItemRenderer}
        noDataLabel={getLocalization('noItemFound')}
      />
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={className}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10}>
            {input}
          </Col>
        ) : input}
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
        {hasError && errorText && (
          <div className="invalid-value-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }

  @bind
  private renderStringInputTyped(): JSX.Element {
    const { value, hasError, errorText } = this.state;
    const { question, formUtils, dataPoint, type } = this.props;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const className =
      ` ${this.state.error ? 'has-error' : ''}
      ${!horizontal ? formUtils.getResponsiveView(question) : 'container-fluid'}`;
    const hasErrorClass = hasError ? 'is-invalid' : '';
    const input = (
      <TextInputComponent
        name={question.id}
        value={value}
        onChange={this.doneTypingWithValidity}
        type={type}
        disabled={!this.state.edit}
        extraClass={hasErrorClass}
      />
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={className}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10}>
            {input}
          </Col>
        ) : input}
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
        {hasError && errorText && (
          <div className="invalid-value-feedback">{errorText}</div>
        )}
      </Form.Group>
    );
  }

  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
  public static getDerivedStateFromProps(props: StringQuestionProps, state: State): Partial<State> | null {
    const { question, dataPoint, updateAnswer, formUtils } = props;
    const { value } = state;
    const newState = {};
    // Set the default value if the datamodel has no value
    if (!dataPoint[question.id] && question.default && value === question.default) {
      const newValue = {};
      newValue[question.id] = question.default;
      updateAnswer(newValue);
      newState['value'] = question.default;
    }

    if (question.convertToText && dataPoint.id) {
      if (!state.placeholder && props.transcribing.dataPointId === dataPoint.id) {
        const hasAudio = dataPoint.files?.find(f => f.questionId === question.id && f.mimeType?.indexOf('audio') > -1);
        if (hasAudio) {
          newState['placeholder'] = getLocalization('transcribingAudio');
        }
      } else if (state.placeholder) {
        newState['placeholder'] = '';
      }
    }
    if (dataPoint[question.id] && dataPoint[question.id] !== value) {
      newState['value'] = dataPoint[question.id];
    } else if (dataPoint['validate']) {
      if (!question.optional && value === '') {
        return { hasError: true };
      }
      let hasError = false;
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        hasError = errors.hasError;
        errorText = errors.errorText;
      }
      return { hasError, errorText };
    }
    if (Object.keys(newState).length > 0) {
      return newState;
    }
    return null;
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public componentDidUpdate(prevProps: Readonly<StringQuestionProps>): void {
    const { dataPoint, question } = this.props;
    const { value } = this.state;
    if (dataPoint[question.id] !== prevProps.dataPoint[question.id] && dataPoint[question.id] !== value) {
      this.setState({ value: dataPoint[question.id]});
    }
  }

  /* public shouldComponentUpdate(nextProps, nextState: State) {
    const { question, dataPoint } = this.props;
    let ret = this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || this.state.error !== nextState.error || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.props.dataPoint.files !== nextProps.dataPoint.files || nextState.hasError !== this.state.hasError
      || nextState.errorText !== this.state.errorText || this.state.placeholder !== nextState.placeholder
      || dataPoint[question.id] !== nextProps.dataPoint[question.id];
    if (question.validatefield && question.validationlist) {
      ret = ret || this.state.validationList !== nextState.validationList;
    }
    return ret;
  } */

  public render(): JSX.Element {
    const { question, type } = this.props;
    if (question.validatefield && question.validationlist) {
      return this.renderValidationInput();
    } else if (type) {
      return this.renderStringInputTyped();
    } else {
      return this.renderStringInput();
    }
  }
}
