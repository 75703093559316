/*
 * Renders the chart box with the chart.
 */
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import Highcharts from "highcharts";
// import * as Highcharts from 'highcharts/highstock';
import stock from "highcharts/modules/stock";
import HighchartsReact from 'highcharts-react-official';
import highchartsExport from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import highchartsExportLocal from 'highcharts/modules/offline-exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartModel } from 'Interfaces/ChartInterface';

stock(Highcharts);
highchartsExport(Highcharts);
highchartsExportLocal(Highcharts);
HighchartsMore(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.AST.allowedAttributes.push('onclick');

interface Props {
  options: Highcharts.Options;
  id: string;
  switchChart: (index, action: boolean, name: string) => void;
  index: number;
  chartType: string;
  addChartSvgs: (svg: SVGElement, id) => void;
  model: ChartModel;
  dashboard?: boolean;
  removeChart?: () => void;
}

interface State {
  cummulative: boolean;
  sortDesc: boolean;
  sortAsc: boolean;
  switch: boolean;
  canSwitch: boolean;
}

export default class ChartBoxComponent extends React.Component<Props, State> {
  private chartRef: React.RefObject<HighchartsReact>;
  // private container = React.createRef<HTMLDivElement>();
  // private chart: any;
  constructor(props) {
    super(props);
    // this.container = React.createRef();
    this.state = {
      cummulative: false,
      sortDesc: false,
      sortAsc: false,
      switch: false,
      canSwitch: this.canSwitch()
    };
    this.chartRef = React.createRef();
  }

  private canSwitch() {
    const { model } = this.props;
    if ((model.type === 'BAR_CHOICE' && !model.stack && !model.combine) ||
      (model.type === 'BAR_NUMERIC' && model.combine && model.xaxis)) {
      return true;
    }
    return false;
  }

  public componentDidMount(): void {
    this.updateChartSvgs();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.options !== this.props.options) {
      this.updateChartSvgs();
      const chart = this.chartRef.current?.chart;
      if (chart) {
        chart.update(this.props.options, true, true);
        chart.reflow();
      }
    }
  }

  private updateChartSvgs(): void {
    const chart = this.chartRef.current?.chart;
    const svg = chart?.getSVG() as unknown as SVGElement;
    if (svg) {
      this.props.addChartSvgs(svg, this.props.id);
    }
  }

  @bind
  private onSwitchChart(name: string) {
    const { id, switchChart } = this.props;
    const { sortAsc, sortDesc } = this.state;
    const state = {};
    state[name] = !this.state[name];
    if (name === 'sortAsc') {
      this.setState({ sortAsc: !sortAsc, sortDesc: false }, () => {
        switchChart(id, !sortAsc, name);
      });
    } else if (name === 'sortDesc') {
      this.setState({ sortDesc: !sortDesc, sortAsc: false }, () => {
        switchChart(id, !sortDesc, name);
      });
    } else {
      this.setState(state, () => {
        switchChart(id, this.state[name], name);
      });
    }
  }

  @bind
  private renderCustomHtml() {
    const { options } = this.props;
    console.log(options);
  }
  /* public componentDidMount() {
     this.chart = new Highcharts['Chart'](this.props.id, this.props.options);
     console.log(this.chart);
  }*/

  /* public shouldComponentUpdate(nextProps, nextState) {
    if (this.props.options !== nextProps.options) {
      this.chart = new Highcharts['Chart'](this.props.id, nextProps.options);
      // this.chart.update(nextProps.options);
    }
    return true;
  }*/

  public render() {
    const { model, options, dashboard, removeChart } = this.props;
    const { sortAsc, sortDesc } = this.state;
    return (
      <div
        className={`col${!dashboard ? model.type === 'RADAR' ? '-9' : '-6' : ' dashboard-chart-container'}`}
        key={`${model.id}`}
      >
        <div className="container">
          <div className={`row ${!dashboard ? 'justify-content-end' : 'justify-content-between'}`}>
            {dashboard ? (<div><h5>{model.name}</h5></div>) : null}
            <span>
              {(model.type === 'BAR_NUMERIC') && (
                <>
                  <Button
                    size="sm"
                    onClick={() => this.onSwitchChart('sortAsc')}
                    variant={sortAsc ? 'success' : 'light'}
                  >
                    <i className="fa fa-sort-amount-asc"/>
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.onSwitchChart('sortDesc')}
                    variant={sortDesc ? 'success' : 'light'}
                  >
                    <i className="fa fa-sort-amount-desc"/>
                  </Button>
                </>
              )}
              {(model.type === 'LINE_NUMERIC' || model.type === 'LINE_CHOICE') && (
                <Button
                  size="sm"
                  onClick={() => this.onSwitchChart('cummulative')}
                  variant={this.state.cummulative ? 'success' : 'light'}
                >
                  <i className="fa fa-line-chart"/>
                </Button>
              )
              }
              {this.state.canSwitch && !dashboard && (
                <Button
                  size="sm"
                  onClick={() => this.onSwitchChart('switch')}
                  variant={this.state.switch ? 'success' : 'light'}
                >
                  <i className="fa fa-arrows-h"/>
                </Button>
              )}
              {dashboard && removeChart && (
                <Button
                  size="sm"
                  onClick={removeChart}
                  variant={'danger'}
                >
                  <i className="fa fa-trash"/>
                </Button>
              )}
            </span>
          </div>
        </div>
        <div id={this.props.id} className="chart-box-content">
          <HighchartsReact
            ref={this.chartRef}
            highcharts={Highcharts}
            constructorType={'chart'}
            options={options}
            updateArgs={[true]}
            callback={this.renderCustomHtml}
          />
        </div>
        {options['extraTitle'] && !(options.legend && options.legend.enabled) && (
          <ul className="chart-title-list">
            {this.props.options['extraTitle'].map((title, index) => {
              return (
                <li key={`chart-${model.id}-${index}`}>{title}</li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
