import { Action } from 'typescript-fsa';
import { createSelector } from 'reselect';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import { StateInterface } from '../Interfaces/StateInterface';
import {
  ClientPersistInterface,
  languagesEnum
} from '../Interfaces/ClientPersistInterface';
import { persistAuthorizationKey } from '../global/constants';
import { CLIENT_PERSIST, USER } from '../actions/actionTypes';
import ReducerBuilder from './ReducerBuilder';

const authorizationPersistConfig: PersistConfig<ClientPersistInterface> = {
  key: persistAuthorizationKey,
  storage,
};

type PayloadType = ClientPersistInterface | languagesEnum;

// const INITIAL_STATE: ClientPersistInterface = globalWindow.clientPersistModel.attributes;
export const INITIAL_STATE: ClientPersistInterface = {
  chartVid: '',
  showDataAutomatically: true,
  rememberFilterSelections: true,
  forms: [],
  locations: [],
  users: [],
  fromDate: '',
  toDate: '',
  clusterMap: true,
  lang: languagesEnum.en,
  lastLogin: '',
  new_data_notification: false,
  updated_data_notification: false,
  updated_created_data_notification: false,
  useremail: '',
  disableMobileFeatures: false,
  epaymentUser: false,
  instance: undefined,
  organization: undefined,
  package: '',
  paymentMode: '',
  roles: '',
  userName: undefined,
  user_id: undefined,
  userLevel: '',
  userLocations: '',
  locationLabels: [],
  payingCustomer: '',
  groupId: undefined,
  readOwn: undefined,
  googleAccessToken: undefined,
  key: '',
  fullZoom: undefined,
  shareDataUnregisteredUsers: false,
  accessSharedDataOnly: false,
  canDelete: false,
  refreshToken: undefined,
  assignedLocationOnly: undefined,
  oneDriveAccessToken: undefined,
  sessionTime: Date.now(),
  shareDataUnregisteredUsersViewer: false,
  locationHierarchyId: -1,
};

const update = (state: ClientPersistInterface, { payload }): ClientPersistInterface => {
  return { ...state, ...payload };
};

const reset = (state: ClientPersistInterface): ClientPersistInterface => {
  return { ...state, user_id: undefined, userName: undefined, instance: undefined, checkTwoFactorAuthCode: false };
};

const setLanguage = (state: ClientPersistInterface, { payload }): ClientPersistInterface => {
  return { ...state, lang: payload };
};

const loginSuccess = (state: ClientPersistInterface, { payload }): ClientPersistInterface => {
  return { ...state, ...payload };
};

const updatePassword = (state: ClientPersistInterface): ClientPersistInterface => {
  return { ...state, passwordExpired: false };
};

export const clientPersistReducer = new ReducerBuilder<ClientPersistInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(CLIENT_PERSIST.UPDATE, update)
  .addReducer(CLIENT_PERSIST.RESET, reset)
  .addReducer(CLIENT_PERSIST.SET_LANG, setLanguage)
  .addReducer(USER.LOGOUT, reset)
  .addReducer(CLIENT_PERSIST.UPDATE_FROM_LEGACY, update)
  .addReducer(USER.LOGIN_SUCCESS, loginSuccess)
  .addReducer(USER.UPDATE_PASSWORD_OR_EMAIL, updatePassword)
  .build();

export default persistReducer<ClientPersistInterface, Action<any>>(authorizationPersistConfig, clientPersistReducer);

// Selectors
export const clientPersistSelector = (state: StateInterface): ClientPersistInterface => state.clientPersist;

export const userNameSelector = createSelector<
StateInterface,
ClientPersistInterface,
ClientPersistInterface['userName']
>([
  clientPersistSelector
], (clientPersist) => clientPersist.userName);

export const languageSelector = createSelector<StateInterface, ClientPersistInterface, string>([
  clientPersistSelector
], (clientPersist) => {
  if (clientPersist.instance === 'safercities') {
    let lang: string = clientPersist.lang;
    if (!lang) {
      lang = navigator.language;
      lang = lang.indexOf('-') !== -1 ? lang.substring(0, lang.indexOf('-')) : lang;
    }
    return ['ca', 'es', 'fr', 'nl'].indexOf(lang) !== -1 ? lang : 'en';
  } else {
    return clientPersist.lang;
  }
});

export const formLanguageSelector = createSelector<StateInterface, ClientPersistInterface, string>([
  clientPersistSelector
], (clientPersist) => {
  return clientPersist.formLanguage || clientPersist.lang;
});
