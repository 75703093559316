import { DashboardMenuInterface } from 'Interfaces/DashBoardInterface';
import { DASHBOARD_ACTIONS } from '../actions/actionTypes';

const INITIAL_STATE:DashboardMenuInterface  = {
  active: undefined
};

const dashboardMenu = (state = INITIAL_STATE, action): DashboardMenuInterface => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.SELECT:
      if (action.active) {
        return {
          active: action.active
        };
      }
      return { active: undefined };
    case 'dashboard/dashboardsLoaded': {
      const selected = action.payload.find((d => d.showOnLogin));
      if (selected) {
        return {
          active: `${selected.id}`
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default dashboardMenu;
