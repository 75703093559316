export enum questionTypes {
  ID_QUESTION = 'IdQuestion',
  PART_OF_QUESTION = 'Part_of_Question',
  TASK_QUESTION = 'TaskQuestion',
  BOOLEAN_QUESTION = 'BooleanQuestion',
  SELECT_ONE_QUESTION = 'SelectOneQuestion',
  SELECT_MULTIPLE_QUESTION = 'SelectMultipleQuestion',
  STATUS_QUESTION = 'StatusQuestion',
  LIKERT_SCALE_QUESTION = 'LikertScaleQuestion',
  NAME_QUESTION = 'NameQuestion',
  CALCULATED_VALUE_QUESTION = 'CalculatedValueQuestion',
  PICTURES_QUESTION = 'PicturesQuestion',
  TIME_QUESTION = 'TimeOfDayQuestion',
  TABLE_QUESTION = 'TableQuestion',
  REFERENCE_TABLE_QUESTION = 'ReferenceTableQuestion',
  LABEL = 'Label',
  HEADER_QUESTION = 'HeaderQuestion',
  GPS_QUESTION = 'GPSQuestion',
  VALIDATION_RULE_QUESTION = 'ValidationRuleQuestion',
  FILE_QUESTION = 'FileQuestion',
  SKIP_QUESTION = 'SkipQuestion',
  LOOKUP_VALUES_QUESTION = 'LookupValuesQuestion',
  INT_QUESTION = 'IntQuestion',
  STRING_QUESTION = 'StringQuestion',
  FLOAT_QUESTION = 'FloatQuestion',
  DATE_QUESTION = 'DateQuestion',
  DIGITAL_SIGNATURE_QUESTION = 'DigitalSignatureQuestion',
  SELECT_USER_QUESTION = 'SelectUserQuestion',
  USER_QUESTION = 'UserQuestion',
  LAYOUT_TABLE_QUESTION = 'LayoutTableQuestion',
  EMAIL_QUESTION = 'EmailQuestion',
  PHONE_NUMBER_QUESTION = 'PhoneNumberQuestion',
  RANK_ORDER_QUESTION = 'RankOrderQuestion',
  TIME = 'TimeQuestion'
}

export interface QuestionAttributesInterface {
  range: null;
  inVisible: boolean;
  optional: boolean;
  helpDescription: null;
  icon: null;
  units: null;
  constant: true;
  color: null;
  regexp: null | string;
  maxlen?: number;
  minlen?: number;
  greater?: boolean;
  greaterOrEqual?: boolean;
  less?: boolean;
  lessOrEqual?: boolean;
  useLastSavedValue: boolean;
  attachImage: boolean;
  validatefield: boolean;
  validationlist?: string | null;
  hideInDevice: boolean;
  hideInPortal: boolean;
  hideInPopup: boolean;
  clazz: string | null;
  script: string;
  evaluate?: string;
  operation?: boolean;
  readOnlyInDevice: boolean;
  accept: string | null;
  iconData: null;
  value?: string;
  lookupValue: null | string;
  includeFile: boolean;
  edit?: string;
  referencedRows: boolean;
  title?: string;
  runOnCreate: boolean;
  recordEditorEditTime: boolean;
  preserveValue: boolean;
  incremental: boolean;
  saveDraftUnlessValid: boolean;
  changePageUnlessValid: boolean;
  alertText: string | null;
  salesForceId: boolean;
  salesForceURL?: string;
  enableBarCodeScan: boolean;
  numericCodes: boolean;
  officeUsers?: boolean;
  minDate: string | null;
  maxDate: string | null;
  enableMinDate: boolean;
  enableMaxDate: boolean;
  officeRoles?: boolean;
  includeSubgroup: boolean;
  hideInTable: boolean;
  enumUsers?: boolean;
  enumRoles?: boolean;
  enumSubgroup: boolean;
  officeSubgroup: boolean;
  numericOnly: boolean;
  hideInSingleInstance: boolean;
  mimeType?: string;
  showSize?: boolean;
  storeTrace?: boolean;
  mediaId?: boolean;
  subgroup: boolean;
  users: any;
  fileMandatory: boolean;
  hideInExport: boolean;
  clearFalseBranchData: boolean;
  deleted: boolean;
  notificationHeader?: boolean;
  notificationBody?: boolean;
  sendNotification: boolean;
  salesForceName: string;
  webHook?: string;
  voting?: boolean;
  editAddData?: boolean;
  static: boolean;
  view?: string;
  actionPerRow: boolean;
  roles?: string;
  index?: number;
  pageIndex?: number;
  allowedAttachments?: number;
  fieldsAsRows?: boolean;
  styleBoolean?: boolean;
  showOptionsHorizontally?: boolean;
  multiChoiceValidationList?: boolean;
  showValueInParallel?: boolean;
  showInSelectionList?: boolean;
  filterCondition?: string;
  convertToText?: boolean;
}

export interface QuestionInterface extends QuestionAttributesInterface {
  triggerValues?: {
    triggerValue: TriggerValue[];
  };
  listItems?: {
    listItem: ListItem[];
    relation: [
      {
        ref: string;
        id: string;
        source: string;
        text: string;
      },
    ];
  };
  table?: Table | null;
  id: string;
  type: questionTypes;
  text: string;
  spssvariable: string;
  default: string;
}

export interface ListItem {
  id?: string;
  value: string;
  code?: string;
  spssVariable?: string;
  imageId?: string;
  mediaId?: string;
  mimeType?: string;
  rowValue?: string;
  color?: string;
}

export interface Relation {
  ref: string;
  id: string;
  source: string;
  text: string;
}

export interface Column {
  id: string;
  name: string;
  question: QuestionInterface[];
}

interface Columns {
  relation: Relation[];
  column: Column[];
}

interface Row {
  listItem: ListItem[];
}
interface Table {
  columns: Columns;
  rows?: Row;
}

interface SubQuestions {
  question: QuestionInterface[];
}
interface Action {
  subQuestions: SubQuestions;
}

interface TriggerValue {
  value: string;
  action: Action;
}
