import { Action } from 'typescript-fsa';
import { createSelector } from 'reselect';
import {
  FormInterface, FormOriginalText, FormsInterface, OriginalFormText, sortFormsByName
} from '../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { FORMS } from '../actions/actionTypes';
import { FiltersMenuInterface, filtersSelectedFormType } from '../Interfaces/FiltersMenuInterface';
import { QuestionPageInterface } from '../Interfaces/Forms/QuestionPageInterface';
import { QuestionInterface, questionTypes } from '../Interfaces/Forms/QuestionInterface';
import ReducerBuilder from './ReducerBuilder';
import { filtersMenuSelectedFormsSelector } from './filtersMenuReducer';
import { formLanguageSelector } from './clientPersistReducer';

export const INITIAL_STATE: FormsInterface = {
  loaded: false,
  collection: [],
  originalFormText: {}
};

export interface LoadFormHierarchyPayload {
  ref: FormInterface['ref'];
  hierarchyCounts: FormInterface['hierarchyCounts'];
}

export interface ValidationListUpdatePayload {
  formId: string;
  questionId: string;
  value: string;
}

export type PayloadType = FormInterface[] | LoadFormHierarchyPayload;

function loadForms(state: FormsInterface, { payload }): FormsInterface {
  return {
    loaded: true,
    collection: payload,
    originalFormText: getFormsOriginalText(payload)
  };
}

function loadFormHierarchyPayload(state: FormsInterface, { payload }): FormsInterface {
  let form = state.collection.find((form) => form.ref === payload.ref);
  if (form) {
    const collection = state.collection.filter((form) => form.ref !== payload.ref);
    form = { ...form, hierarchyCounts: payload.hierarchyCounts };
    return {
      ...state,
      collection: [...collection, form],
    };
  }
  return state;
}

const updateQuestionValidationList = (questions: QuestionInterface[], id: string, newValue: string, add: boolean) => {
  for (const qn of questions) {
    if (qn.id === id) {
      if (add) {
        qn.validationlist = `${qn.validationlist};${newValue}`;
      } else {
        qn.validationlist = qn.validationlist?.split(';').filter(
          v => v.toLowerCase().trim() !== newValue.toLowerCase().trim()
        ).join(';');
      }
    }
  }
};

function updateValidationList(state: FormsInterface, { payload }): FormsInterface {
  const form = state.collection.find((form) => form.ref === payload.formId);
  if (form) {
    const pages = form.questionPage;
    if (pages) {
      for (const page of pages) {
        updateQuestionValidationList(page.question || [], payload.questionId, payload.value, true);
      }
    }
    updateQuestionValidationList(form.question || [], payload.questionId, payload.value, true);
    return {
      ...state,
      collection: [...state.collection.filter((form) => form.ref !== payload.formId), form],
    };
  }
  return state;
}

function removeValidationList(state: FormsInterface, { payload }): FormsInterface {
  const form = state.collection.find((form) => form.ref === payload.formId);
  if (form) {
    const pages = form.questionPage;
    if (pages) {
      for (const page of pages) {
        updateQuestionValidationList(page.question || [], payload.questionId, payload.value, false);
      }
    }
    return {
      ...state,
      collection: [...state.collection.filter((form) => form.ref !== payload.formId), form],
    };
  }
  return state;
}

export default new ReducerBuilder<FormsInterface, Action<PayloadType>>()
  .setInitialState(INITIAL_STATE)
  .addReducer(FORMS.LOAD, loadForms)
  .addReducer(FORMS.ADD, updateValidationList)
  .addReducer(FORMS.REMOVE, removeValidationList)
  .addReducer(FORMS.LOAD_HIERARCHY_COUNTS, loadFormHierarchyPayload)
  .build();

// Selectors
export const formsSelector = (state: StateInterface): FormsInterface => state.forms;

export const uniqueFormsIDsSelector = createSelector<StateInterface, FormsInterface, string[]>(
  formsSelector,
  (forms) => {
    const ids: string[] = [];
    if (forms.collection) {
      forms.collection.forEach((form: FormInterface) => {
        if (ids.indexOf(form.ref) === -1) {
          ids.push(form.ref);
        }
      });
    }
    return ids;
  },
);

export const uniqueFormsSelector = createSelector<StateInterface, string, FormsInterface, string[], FormInterface[]>(
  formLanguageSelector,
  formsSelector,
  uniqueFormsIDsSelector,
  (lang, forms, ids) => {
    const filteredForms = ids.map((id) => {
      let form: FormInterface | undefined;
      let anyForm: FormInterface | undefined;
      forms.collection.forEach((f) => {
        if (f.ref === id) {
          // if there is a form with no view language we set it as the form to be used.
          if ((f.lang === '' || !f.lang) && !form) {
            form = f;
          } else if (f.lang === lang) {
            // if we encounter a form which has a view that matches the language set we set it to be used.
            form = f;
          } else {
            // here we just assign any form found to this temp variable. if no form matches the language.
            // whichever form will be held by this variable is what will be used.
            anyForm = f;
          }
        }
      });
      if (form) {
        return form;
      }
      return anyForm;
    });
    const cleanedForms: FormInterface[] = filteredForms.filter((f) => f !== undefined);
    return cleanedForms;
  },
);

export const nonChildFormsSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
  formsSelector,
  ({ collection }) => (collection ? collection.filter((form) => !form.isChild) : []),
);

export const nonTableTypeFormsSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
  formsSelector,
  ({ collection }) => (collection ? collection.filter((form) => form.type !== 'TABLE') : []),
);

export const formsForFiltersSelector = createSelector<
StateInterface,
FormInterface[],
FormInterface[],
FormInterface[],
FormInterface[]
>(
  uniqueFormsSelector,
  nonChildFormsSelector,
  nonTableTypeFormsSelector,
  (nonChildForms, nonTableForms) => nonChildForms
    .filter(form => nonTableForms.includes(form))
    .sort(sortFormsByName));

export const addPoiFormsSelector = createSelector<StateInterface, FormInterface[], FormInterface[]>(
  formsForFiltersSelector,
  (forms) => forms.filter((f) => !f.dataReadOnly),
);

export const formsWithGPSSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
  formsSelector,
  ({ collection }) => (collection ? collection.filter((form) => !form.isChild && form.hasCoordinates === true) : []),
);

export const addPoiFormsWithGpsSelector = createSelector<StateInterface, FormInterface[], FormInterface[]>(
  formsWithGPSSelector,
  (forms) => forms.filter((f) => !f.dataReadOnly),
);

export const taskFormsSelector = (state: StateInterface): FormInterface[] =>
  state.forms.collection ? state.forms.collection.filter(f => f.type === 'TASKFORM') : [];

// Returns a list of forms that are selected from the checklist
export const selectedFormsSelector = createSelector<
StateInterface,
FormInterface[],
FiltersMenuInterface['selectedForms'],
FormInterface[]
>(
  formsForFiltersSelector,
  filtersMenuSelectedFormsSelector,
  (formsList, selectedForms) => {
    const formReferences = selectedForms.map(({ref}) => ref);
    return formsList.filter(form => formReferences.includes(form.ref));
  }
);

// returns forms that are used for filters and contain images
const filterFormsWithImagesSelector = createSelector<StateInterface, FormInterface[], FormsInterface, FormInterface[]>(
  formsForFiltersSelector, formsSelector, (filterForms, {collection}) => {
    let formsWithImages: FormInterface[] = [];
    filterForms.forEach((filterForm) => {
      let hasImages = filterForm.includeImages;
      if (!hasImages) {
        const questionPages: FormInterface['questionPage'] = filterForm.questionPage || [];
        questionPages.forEach((page: QuestionPageInterface) => {
          page.question?.forEach((pageQuestion: QuestionInterface) => {
            if (!pageQuestion.deleted) {
              // Check if Subforms have TableQuestion
              if ([
                questionTypes.PART_OF_QUESTION,
                questionTypes.TASK_QUESTION
              ].includes(pageQuestion.type)) {
                const subForm = collection.find(form => form.ref === pageQuestion.listItems?.relation[0].ref);
                if (subForm) {
                  if (subForm.includeImages) {
                    hasImages = true;
                  } else if (subForm.questionPage) {
                    subForm.questionPage.forEach((subFormPage) => {
                      // Some pages do not have questions
                      if (subFormPage.question) {
                        subFormPage.question.forEach((question) => {
                          if (!question.deleted
                            && question.includeFile
                            && question.accept
                            && question.accept.includes('image')
                          ) {
                            hasImages = true;
                          }
                        });
                      }
                    });
                  }
                }
              } else if (pageQuestion.attachImage
                || (pageQuestion.includeFile
                && pageQuestion.accept
                && pageQuestion.accept.includes('image')
                )
              ) {
                hasImages = true;
              }
            }
          });
        });
      }
      if (hasImages) {
        formsWithImages = [
          ...formsWithImages,
          filterForm
        ];
      }
    });
    return formsWithImages;
  }
);

// Returns forms that have been filtered and has images
export const selectedFormsWithImagesSelector = createSelector<
StateInterface,
FormInterface[],
FiltersMenuInterface['selectedForms'],
filtersSelectedFormType[]
>(
  filterFormsWithImagesSelector,
  filtersMenuSelectedFormsSelector,
  (filterFormsWithImages, selectedForms) => {
    return selectedForms.filter((selectedForm) => {
      return filterFormsWithImages.some((form) => form.ref === selectedForm.ref);
    });
  }
);

export const formsWithScheduleStatusSelector = createSelector<StateInterface, FormInterface[], FormInterface[]>(
  formsForFiltersSelector,
  (formsFormFilter) => {
    return formsFormFilter.filter((f) => f.hasScheduledStatus);
  },
);

export const getFormsOriginalText = (forms: FormInterface[]) => {
  const originalText: FormOriginalText = {};
  const keySet: string[] = [];

  for (const form of forms) {
    if (form.showOriginalLanguage && form.lang) {
      keySet.push(form.ref);
    }
  }
  if (keySet.length === 0) {
    return originalText;
  }
  const originalForms = forms.filter(f => keySet.includes(f.ref) && (!f.lang || f.lang === ''));
  for (const form of originalForms) {
    originalText[form.ref] = getFormOriginalText(form, forms);
  }
  return originalText;
};

export const getFormOriginalText = (form: FormInterface, forms: FormInterface[]) => {
  const formView: OriginalFormText = {};
  formView[form.ref] = form.name;

  const getQuestionOriginalText = (questions: QuestionInterface[]) => {
    for (const qn of questions) {
      formView[qn.id] = qn.text;
      if (qn.listItems && qn.listItems.listItem) {
        qn.listItems.listItem.forEach(li => {
          if (li.id) {
            formView[li.id] = li.value;
          }
        });
      }
      if (qn.triggerValues && qn.triggerValues.triggerValue) {
        qn.triggerValues.triggerValue.forEach(tv => {
          if (tv.action.subQuestions) {
            getQuestionOriginalText(tv.action.subQuestions.question);
          }
        });
      }
      if (qn.type === questionTypes.TABLE_QUESTION || qn.type === questionTypes.LAYOUT_TABLE_QUESTION) {
        if (qn.table) {
          if (qn.table.columns) {
            if (qn.table.columns.relation && qn.table.columns.relation.length > 0) {
              const ref = qn.table.columns.relation[0].ref;
              const tableForm = forms.find(f => f.ref === ref);
              if (tableForm && tableForm.question) {
                getQuestionOriginalText(tableForm.question || []);
              }
            }
            if (qn.table.columns.column) {
              const columns = qn.table.columns?.column;
              for (const col of columns) {
                formView[col.id] = col.name;
                getQuestionOriginalText(col.question || []);
              }
            }
          }
          if (qn.table.rows) {
            const rows = qn.table.rows;
            rows.listItem.forEach(li => {
              if (li.id) {
                formView[li.id] = li.value;
              }
            });
          }
        }
      }
    }
  };
  if (form.questionPage) {
    for (const page of form.questionPage) {
      getQuestionOriginalText(page.question || []);
    }
  }
  if (form.question) {
    getQuestionOriginalText(form.question || []);
  }
  return formView;
};
