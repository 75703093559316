/*
 * Renders the Chart Panel.
 * This has the header.
 */
import * as React from 'react';
import bind from 'bind-decorator';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import Canvg from 'canvg';
import { Locations } from 'Interfaces/LocationInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { User } from 'Interfaces/User';
import { QueryFiltersInterface } from 'reducers/filtersMenuReducer';
import { getLocalization } from '../../global/global';
import GenericModal from '../Modals/GenericModal';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { LooseObject } from '../../Interfaces/LooseObject';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
// import { ChartReportInterface } from '../../Interfaces/ChartsMenu';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { ListItem } from '../../Interfaces/Forms/QuestionInterface';
import { getFormUtils } from '../SingleInstance/utils/FormUtilsHolder';
import { ChartDataPoints, ChartModel, ChartResponse, SeriesData } from '../../Interfaces/ChartInterface';
import * as ChartUtils from './utils';
import ChartOptionsComponent from './ChartOptionsComponent';
import ChartBoxComponent from './ChartBoxComponent';
import { processChartData } from './utils/ChartData';

interface IExternalProps {
  userName: ClientPersistInterface['userName'];
  form: FormInterface;
  forms: FormInterface[];
  parentForm?: FormInterface;
  model: ChartModel;
  filtersMenu: FiltersMenuInterface;
  chartDataPoints: ChartDataPoints;
  loadChartData: (url: string, callBack: (data) => void) => void;
  loadBarChart: (chartModel: ChartModel) => Promise<Response>;
  locationLabels: string[];
  unSelectChart: (chartId: string) => void;
  selectChart: (chartId: string) => void;
  saveChart: (chart: ChartModel, tempId?) => Promise<Response>;
  deleteChartsReport: (reportId: string) => Promise<void>;
  navigateAddModal: (modal: ModalInterface) => void;
  navigateRemoveModal: (modalName: ModalComponentNames) => void;
  fetchPOI: (formId: string, signal: AbortSignal, rowId?: number,
    filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string) => Promise<Response>;
  locations: Locations;
  setChartDataPoints: (chartId: string, chartDataPoints: ChartDataPoints) => void;
  users: User[];
}

interface IOwnState {
  chartData:  ChartResponse[] | DataPoint[] | undefined;
  charts: LooseObject[] | LooseObject;
  chartKeys: string[];
  edit: boolean;
  showSaveModal: boolean;
  reportName: string;
  model: ChartModel;
  chartsSvgs: SVGElement[];
  rows: ListItem[];
  loading: boolean;
  chartDataPoints: ChartDataPoints;
}

export default class ChartPanel extends React.Component<IExternalProps, IOwnState> {

  private signal: AbortController | null = null;
  constructor(props) {
    super(props);
    this.state = {
      chartData: undefined,
      charts: [],
      chartKeys: [],
      edit: typeof props.model.id === 'string' && props.model.id.startsWith('new-'),
      showSaveModal: false,
      reportName: props.model.name,
      model: props.model,
      chartsSvgs: [],
      rows: this.getTableRows(),
      loading: false,
      chartDataPoints: {}
    };
  }

  @bind
  private getTableRows() {
    const { parentForm, form } = this.props;
    if (parentForm && form.questionId) {
      const fu = getFormUtils(parentForm);
      return fu.getRows(form.questionId);
    }
    return [];
  }

  /*
   * Edit button click handler.
   */
  @bind
  private toggleEdit() {
    this.setState({ edit: !this.state.edit });
  }

  @bind
  private saveChart(visible) {
    const state = {};
    state['showSaveModal'] = visible;
    if (!visible) {
      state['reportName'] = '';
    }
    this.setState(state);
  }

  @bind
  private handleChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  @bind
  private updateChartsSvgs(svg: SVGElement) {
    const {chartsSvgs} = this.state;
    chartsSvgs.push(svg);
    this.setState({chartsSvgs: chartsSvgs});
  }

  @bind
  private getSaveModalBody() {
    return (
      <Form.Group>
        <Form.Label>{getLocalization('reportName')}</Form.Label>
        <Form.Control type="text" name="reportName" value={this.state.reportName} onChange={this.handleChange} />
      </Form.Group>
    );
  }

  @bind
  private onSaveChart() {
    const { model } = this.state;
    model.name = this.state.reportName;
    const tempId = model.id;
    model.id = `${model.id}`.startsWith('new-') ? '' : model.id;
    this.setState({ showSaveModal: false });
    const savePromise = this.props.saveChart(model, tempId);
    savePromise.then((response) => response.json()).then(json => {
      const id = json.id;
      this.props.unSelectChart(`${tempId}`);
      this.props.selectChart(`${id}`);
      model.id = id;
      this.setState({ model });
    }).catch((error) => {
      console.log(error);
    });
  }

  @bind
  private onDeleteChartReport() {
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          void this.props.deleteChartsReport(this.props.model.id);
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('deletes'),
          confirmStyle: 'danger',
          header: (
            <Modal.Title>
              {getLocalization('reportConfirmDelete')}
            </Modal.Title>
          ),
          body: (
            <React.Fragment>
              {getLocalization('deleteChart').replace('{{name}}', this.props.model.name)}
            </React.Fragment>
          )
        }
      }
    });
  }

  /* @bind
  private onChartSaved(response) {
    const model = {...this.state.model};
    model.id = response.message;
    this.setState({ model });
  }*/

  @bind
  private applyFilters() {
    const  { filtersMenu } = this.props;
    const model = {...this.state.model};
    const locations: string[] = [];
    const levels: string[] = [];
    filtersMenu.selectedLocations.forEach(location => {
      locations.push(`${location.key}`);
      levels.push(`${Number(location.level) + 1}`);
    });
    model.locations = locations.join(',');
    model.locationLevels = levels.join(',');
    model.fromDate = filtersMenu.selectedDates.from || '';
    model.toDate = filtersMenu.selectedDates.to || '';
    model.users = filtersMenu.selectedUsers.map(u => u.id).join(',');
    this.setState({ model }, () => this.loadChart());
  }

  /*
   * When a user presses done.
   * we set the url to the model and set edit to false.
   */
  @bind
  private onDone() {
    const model = {...this.state.model};
    // model.url = url;
    this.setState({ model: model, edit: false });
  }
  /*
   * Callback function for when chart data is loaded.
   */
  @bind
  private chartLoaded(data: ChartResponse[] | DataPoint[], model: ChartModel) {
    const chartKeys: string[] = [];
    const groupedCharts: any = null;
    const { form, forms, locations } = this.props;
    /* if (model.type !== 'RADAR') {
      const formUtils = getFormUtils(form);
      data = data.sort((a, b) => {
        const aQ = formUtils.getQuestion(a.questionId);
        const bQ = formUtils.getQuestion(b.questionId);
        if (aQ.index && bQ.index) {
          if (aQ.index < bQ.index) {
            return -1;
          } else if (aQ.index > bQ.index) {
            return 1;
          }
        }
        return 0;
      });
    } */
    const charts = ChartUtils.createCharts(model, data, form, forms, locations, this.state.chartDataPoints);
    this.setState({
      chartData: data,
      charts: groupedCharts || charts,
      chartKeys,
      chartsSvgs: [],
      model
    });
    void this.loadDataPoints(model);
  }

  @bind
  private async loadDataPoints(model: ChartModel) {
    const { forms } = model;
    const keys = Object.keys(forms);

    const chartDataPoints: ChartDataPoints = {};
    const query = {};
    if (model.locations && model.locationLevels) {
      const locations = model.locations?.split(',');
      const levels = model.locationLevels?.split(',');
      locations.forEach((loc, index) => {
        const level = Number(levels[index]);
        const key = `location${level + 1}`;
        if (!query[key]) {
          query[key] = [];
        }
        query[key].push(loc);
      });
    }
    const filters: QueryFiltersInterface[] = [];
    if (model.users) {
      filters.push({ condition : 'IN', field : 'users', value : model.users, operator : '0', fieldOperator : '1' });
    }
    if (model.dateFilterBy) {
      if (model.fromDate) {
        filters.push({
          condition : 'GREATER_THAN_OR_EQUAL',
          field: model.dateFilterBy,
          value: model.fromDate,
          operator: '0',
          fieldOperator: '1'
        });
      }
      if (model.toDate) {
        filters.push({
          condition : 'GREATER_THAN_OR_EQUAL',
          field: model.dateFilterBy,
          value: model.toDate,
          operator: '0',
          fieldOperator: '1'
        });
      }
    }
    if (!this.signal) {
      this.signal = new AbortController();
    }

    for (const formId of keys) {
      const fields = [...forms[formId].questions];

      if (model.xaxis) {
        if (model.xaxis.indexOf('when-updated') > -1) {
          fields.push('modified');
        } else if (model.xaxis.indexOf('when-created') > -1) {
          fields.push('created');
        } else {
          fields.push(model.xaxis);
        }
      }
      if (model.grouping) {
        fields.push(`location${model.grouping}`);
      }
      const response = await this.props.fetchPOI(
        formId, this.signal.signal, undefined, filters, query,
        ['Name', 'questionnaire_id'].concat(fields).join(',')
      );
      const json = await response.json();

      if (Array.isArray(json)) {
        const { forms, locations, users } = this.props;
        const form = forms.find(f => f.ref === formId);
        if (form) {
          chartDataPoints[formId] = processChartData(model, json, form, locations, users);
        }
      }
    }
    if (this.state.chartData) {
      const { forms, locations } = this.props;
      const charts = ChartUtils.createCharts(model, this.state.chartData, undefined, forms, locations, chartDataPoints);
      this.props.setChartDataPoints(`${model.id}`, {...chartDataPoints});
      const keys = Object.keys(chartDataPoints);
      const newChartDps = {};
      keys.forEach(key => newChartDps[key] = []);
      this.setState({ chartDataPoints: newChartDps, charts });
    }
  }

  @bind
  private switchChart(id: string, action: boolean, name?: string) {
    const { chartData, charts, model } = this.state;
    const { forms, form, chartDataPoints, locations } = this.props;
    if (chartData) {
      if (name === 'switch') {
        if (model.type === 'BAR_NUMERIC' && model.combine) {
          if (action) {
            const c = ChartUtils.switchNumericCharts(chartData, model, this.props.locations);
            let switchedCharts = [];
            if (Array.isArray(c)) {
              if (model.stack) {
                const seriesData: SeriesData[] = [];
                c.forEach(cc => {
                  cc.seriesData.forEach(sd => {
                    sd.stack = sd.name;
                    sd.name = cc.title;
                    seriesData.push(sd);
                  });
                });
                const categories = c[0].categories;
                switchedCharts = switchedCharts.concat(
                  ChartUtils.getCombinedCharts(
                    { categories, seriesData }, model, undefined, forms, undefined, chartDataPoints
                  )
                );
              } else {
                c.forEach(cc => {
                  switchedCharts = switchedCharts.concat(
                    ChartUtils.getCombinedCharts(cc, model, undefined, forms, cc.title, chartDataPoints)
                  );
                });
              }
            } else {
              switchedCharts = ChartUtils.getCombinedCharts(c, model, undefined, forms, undefined, chartDataPoints);
            }
            this.setState({ charts: switchedCharts });
          } else {
            const switchedCharts = ChartUtils.getBarCharts(
              model, chartData, form, this.props.forms, this.props.locations, chartDataPoints
            );
            this.setState({ charts: switchedCharts });
          }
        } else {
          let chart = chartData.find((cd) => cd.id === id);
          if (chart) {
            if (action) {
              chart = ChartUtils.switchChart(chart);
            }
            const readyChart = ChartUtils.getBarCharts(model, [chart], form, forms, undefined, chartDataPoints);
            const newCharts = charts.map((ch) => {
              if (ch.responseId !== id) {
                return ch;
              }
              if (readyChart.length > 0) {
                const r = readyChart.splice(0, 1);
                return r[0];
              }
              return null;
            }).filter(c => c !== null);
            this.setState({ charts: newCharts });
          }
        }
        return;
      } else if (name === 'cummulative') {
        if (model.combine === 'FIELD') {
          const charts = ChartUtils.getCombinedLineChart(model, chartData, action, locations);
          this.setState({ charts });
        } else if (model.combine === 'LOCATION') {
          const charts = ChartUtils.getCombinedLocationLineChart(
            model, chartData, forms, action, locations, chartDataPoints
          );
          this.setState({ charts });
        } else {
          const chart = chartData.find((cd) => cd.id === id);
          if (chart) {
            const newChart = ChartUtils.getLineChart(model, chart, forms, action, locations, chartDataPoints);
            const newCharts = charts.map((ch) => {
              if (ch.responseId === id) {
                return newChart;
              }
              return ch;
            });
            this.setState({ charts: newCharts });
          }
        }
        return;
      } else if (name?.startsWith('sort')) {
        const chart = chartData.find((cd) => cd.id === id);
        if (chart) {
          const newChart = ChartUtils.getBarChart(
            model, chart, undefined, this.props.locations, chartDataPoints, action, name
          );
          const newCharts = charts.map(ch => {
            if (ch.responseId !== id) {
              return ch;
            }
            return newChart[0];
          });
          this.setState({ charts: newCharts });
        }
      }
    }
  }

  @bind
  private loadChart() {
    const { model } = this.state;
    if (model.type === 'RADAR') {
      if (!this.signal) {
        this.signal = new AbortController();
      }
      const query = {
        id: model.dataPoints.join(',')
      };
      const formIds = Object.keys(model.forms);
      const response = this.props.fetchPOI(
        formIds[0], this.signal.signal, undefined, undefined, query,
        ['Name', 'questionnaire_id'].concat(model.forms[formIds[0]].questions).join(',')
      );
      response.then(res => res.json()).then(json => {
        if (!json.errorCode) {
          this.chartLoaded(json, this.state.model);
        }
      }).catch(() => {
        this.setState({ loading: false });
      });
    } else {
      const loadPromise = this.props.loadBarChart(model);
      loadPromise.then(response => response.json()).then(json => {
        this.chartLoaded(json, model);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  /*
   * if this is an existing chart then we load it.
   */
  public componentDidMount() {
    const { model } = this.state;
    if (model) {
      if (model.id && !`${model.id}`.startsWith('new')) {
        this.loadChart();
      }
      // const url = ChartUtils.getRequestUrl(model);
      // this.props.loadChartData(url, (data) => this.chartLoaded(data, ChartUtils.deconstructURL(model, {})));
    }
  }

  private static async svgToPNG(svg, startX: number) {
    let width = parseInt(svg.match(/width="([0-9]+)"/)[1], 10);
    let height = parseInt(svg.match(/height="([0-9]+)"/)[1], 10);
    const aspectRatio = height / width;
    if ((width + 2 * startX) > 595) {
      // If width goes beyond page width, adjust image with aspect ratio
      width = 595 - 2 * startX;
      height = aspectRatio * width;
    }
    const canvasEl: HTMLCanvasElement = document.createElement<'canvas'>('canvas');
    canvasEl.setAttribute('width', width + '');
    canvasEl.setAttribute('height', height + '');
    const ctx: CanvasRenderingContext2D = canvasEl.getContext('2d') as CanvasRenderingContext2D;
    const canvasCreated = await Canvg.from(ctx, svg, {ignoreMouse: true, ignoreAnimation: true});
    await canvasCreated.render();
    return {
      src: canvasEl.toDataURL('image/png'),
      width,
      height
    };
  }

  private static async addPageImage(parameters: {
    doc: jsPDF;
    svg: SVGElement;
    pageNumber: number;
    startX: number;
    startYpos: number;
    generatedBy: string;
    text: string;
  }): Promise<boolean> {
    const {doc, svg, pageNumber, startX, startYpos, generatedBy, text} = parameters;
    const maxWidth = 595.28;
    const maxHeight = 841.89;
    if (pageNumber > 0) {
      doc.addPage();
      doc.setFontSize(10);
    }
    doc.text(text, startX, startYpos);
    const startY = startYpos + 20;
    const imgData = await ChartPanel.svgToPNG(svg, startX);
    doc.addImage(imgData.src, 'PNG', startX, startY, imgData.width, imgData.height);
    doc.setFontSize(8);
    doc.text(generatedBy, 10, maxHeight - 10);
    doc.text((pageNumber + 1).toString(), maxWidth - 20, maxHeight - 10);
    return true;
  }

  private async downloadPdf() {
    const startX = 40;
    const startY = 20;
    const generatedBy = `Generated by ${this.props.userName} on ${new Date().toDateString()}`;
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4' // [595.28, 841.89]
    });
    doc.text(this.props.model.name, startX, startY);
    const imgStartY = startY + 20;
    const chartSvgs = this.state.chartsSvgs;
    for (let i = 0; i < chartSvgs.length; i++) {
      await ChartPanel.addPageImage({
        doc: doc,
        svg: chartSvgs[i],
        pageNumber: i,
        startX: startX,
        startYpos: imgStartY,
        generatedBy: generatedBy,
        text: ''
      });
    }
    doc.save(`${this.props.model.name}.pdf`);
  }

  @bind
  private getChartActions() {
    const { unSelectChart, model } = this.props;
    return (
      <React.Fragment>
        <Button className="close" aria-label="Close" onClick={() => unSelectChart(`${this.state.model.id}`)}>
          <span aria-hidden="true">&times;</span>
        </Button>
        {!this.state.edit && (
          <div className="chart-action-btn">
            <Button size="sm" variant="info" onClick={this.toggleEdit}>
              <i className="fa fa-pencil"/>
            </Button>
            {model.type !== 'RADAR' && (
              <Button size="sm" variant="info" onClick={this.applyFilters}>
                <i className="fa fa-refresh"/>
                {getLocalization('apply_filters')}
              </Button>
            )}
            <Button
              variant="danger"
              size="sm"
              onClick={() => void this.downloadPdf()}
            >
              <i className="fa fa-file-pdf-o"/>
            </Button>
            <Button size="sm" variant="info" onClick={() => this.saveChart(true)}>
              <i className="fa fa-floppy-o"/>
            </Button>
            <Button size="sm" variant="danger" onClick={() => this.onDeleteChartReport()}>
              <i className="fa fa-trash-o"/>
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }

  public render() {
    const { charts, model, showSaveModal, edit } = this.state;
    const saveModal = showSaveModal ? (
      <GenericModal
        visible={this.state.showSaveModal}
        body={this.getSaveModalBody()}
        onConfirm={this.onSaveChart}
        cancel={() => this.saveChart(false)}
        title={getLocalization('reportsFormat')}
        confirmText={getLocalization('save')}
        cancelText={getLocalization('cancel')}
      />
    ) : null;

    /* const getCharts = (list, key) => {
      const chartList = list.map((chart, index) => {
        return (
          <ChartBoxComponent
            key={`${model.id}-chart-${key ? key : ''}-${chart['id']}`}
            id={chart['id']}
            index={index}
            chartType={model.type}
            options={chart}
            switchChart={this.switchChart}
            addChartSvgs={this.updateChartsSvgs}
            model={model}
          />
        );
      });
      if (key) {
        return (
          <Card key={`${key}-chart-card`}>
            <Card.Header>{key}</Card.Header>
            <Card.Body>
              <div className="row">
                {chartList}
              </div>
            </Card.Body>
          </Card>
        );
      }
      return chartList;
    };*/
    const chartBox: any = (
      <div className={`row ${model.type === 'RADAR' ? 'justify-content-center' : ''}`}>
        {charts.map((chart, index) => {
          return (
            <ChartBoxComponent
              key={`${model.id}-chart-${chart['id']}-${index}-${chart['responseId']}`}
              id={chart['responseId']}
              index={index}
              chartType={model.type}
              options={chart}
              switchChart={this.switchChart}
              addChartSvgs={this.updateChartsSvgs}
              model={model}
            />
          );
        })}
      </div>
    );
    /* Array.isArray(charts) ? (
      chartBox[0] = (
        <div className="row" key={`${model.id}_chart_box`}>
          {getCharts(charts, false)}
        </div>
      )
    ) : (
      forOwn(charts, (chart, key) => {
        chartBox = chartBox.concat(getCharts(chart, key));
      })
    );*/
    return (
      <Card className="chart-panel">
        {saveModal}
        <Card.Header>
          <Row>
            <Col md={2}>
              {!edit && (<span className="report-title">{model.name}</span>)}
              {edit && (
                <input
                  className="form-control form-control-sm"
                  value={model.name}
                  onChange={(e) => this.setState({ model: {...model, name: e.target.value }})}
                />
              )}
            </Col>
            <Col md={10}>
              {this.getChartActions()}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {edit && (
            <ChartOptionsComponent
              forms={this.props.forms}
              form={this.props.form}
              model={this.state.model}
              loadChartData={this.props.loadChartData}
              loadBarChart={this.props.loadBarChart}
              locationLabels={this.props.locations.length > 1 ? this.props.locationLabels : []}
              chartLoaded={this.chartLoaded}
              filtersMenu={this.props.filtersMenu}
              onDone={this.onDone}
              fetchPOI={this.props.fetchPOI}
            />
          )}
          {chartBox}
        </Card.Body>
      </Card>
    );
  }
}
