import { getLocalization, globalWindow } from 'global/global';
import { useAppSelector } from 'index';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import GenericModal from 'views/Modals/GenericModal';

interface Props {
  model: FormInterface;
}

export default function Reportutton(props: Props) {
  const { model } = props;
  const filtersMenu = useAppSelector((state: StateInterface) => state.filtersMenu);

  const [show, setShow] = React.useState(false);
  const [exportType, setExportType] = React.useState('');
  const [validate, setValidate] = React.useState(false);

  const exportData = () => {
    const params: string[] = [
      `key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`
    ];
    params.push(`locs=${filtersMenu.selectedLocations.map(l => l.key).join(',')}`);
    params.push(`levels=${filtersMenu.selectedLocations.map(l => l.level ? Number(l.level) + 1 : 0).join(',')}`);
    params.push(`users=${filtersMenu.selectedUsers.map(u => u.id).join(',')}`);
    params.push(`from=${filtersMenu.selectedDates.from}`);
    params.push(`to=${filtersMenu.selectedDates.to}`);
    params.push(`dateFilterBy=${filtersMenu.selectedDates.filterBy}`);

    const url = `/json/app/export/templates-export/${model.ref}?${params.join('&')}`;
    if (!show && model.hasExcelTemplate && model.hasWordTemplate) {
      setShow(true);
    } else {
      if (show) {
        if (exportType === '') {
          setValidate(true);
          return;
        }
        params.push(`type=${exportType}`);
        setValidate(false);
        setShow(false);
      } else {
        params.push(`type=${model.hasWordTemplate ? 'word_template' : 'excel_template'}`);
      }
      globalWindow.open(url, '_blank');
    }
  };

  const modal = show ? (
    <GenericModal
      visible={show}
      title={getLocalization('exportType')}
      cancel={() => setShow(false)}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('exportData')}
      onConfirm={exportData}
      body={(
        <Form.Group>
          <Form.Control
            as='select'
            value={exportType}
            onChange={(e) => setExportType(e.target.value)}
            size='sm'
            isInvalid={validate && exportType === ''}
          >
            <option value={''}>{getLocalization('selectOne')}</option>
            <option value={'word_template'}>{getLocalization('wordExport')}</option>
            <option value={'excel_template'}>{getLocalization('excelExport')}</option>
          </Form.Control>
        </Form.Group>
      )}
    />
  ) : null;

  return (
    <>
      {modal}
      <Button size="sm" onClick={exportData}>
        {getLocalization('report')}
      </Button>
    </>
  );
}
