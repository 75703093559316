import { DashboardInterface, DashboardStateInterface } from 'Interfaces/DashBoardInterface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteDashboard, saveNewDashboard } from 'actions/dashboardActions';

const initialState: DashboardStateInterface  = {
  loaded: false,
  collection: []
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardsLoaded(state, action: PayloadAction<DashboardInterface[]>) {
      return {
        loaded: true,
        collection: action.payload
      };
    },
    addedChartToDashboard (state, action: PayloadAction<DashboardInterface>) {
      return {
        loaded: true,
        collection: state.collection.map(dashboard => {
          if (dashboard.id === action.payload.id) {
            return action.payload;
          }
          return {...dashboard};
        })
      };
    },
    updateDashboardLayout (state, action: PayloadAction<{dashboardId: number; layout: string}>) {
      return {
        loaded: true,
        collection: state.collection.map(dashboard => {
          if (dashboard.id === action.payload.dashboardId) {
            return {...dashboard, layout: JSON.parse(action.payload.layout)};
          }
          return {...dashboard};
        })
      };
    },
    setDashboardShowOnLogin (state, action: PayloadAction<{dashboardId: number; showOnLogin: boolean}>) {
      return {
        loaded: state.loaded,
        collection: state.collection.map(d => {
          if (d.id === action.payload.dashboardId) {
            return {...d, showOnLogin: action.payload.showOnLogin};
          } else if (action.payload.showOnLogin) {
            return {...d, showOnLogin: false};
          }
          return d;
        })
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveNewDashboard.fulfilled, (state, action: PayloadAction<DashboardInterface>) => {
      if (!action.payload) {
        return state;
      }
      return { loaded: true, collection : state.collection.concat(action.payload) };
    }).addCase(deleteDashboard.fulfilled, (state, action: PayloadAction<{ status: string; dashboardId: number}>) => {
      if (action.payload?.status === 'OK' && action.payload.dashboardId) {
        return {loaded: state.loaded, collection: state.collection.filter(d => d.id !== action.payload.dashboardId)};
      }
      return state;
    });
  }
});

export const {
  dashboardsLoaded, addedChartToDashboard, updateDashboardLayout, setDashboardShowOnLogin
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
