import '../styles/FileQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import Select from 'react-dropdown-select';
import ReactDragListView from 'react-drag-listview';
import { questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { isNullOrUndefined } from 'utils/utils';
import { uploadToS3 } from 'api/pois';
import { getLocalization, globalWindow } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { FileQuestionPropsInterface } from '../Containers/FileQuestionContainer';
import { ModalComponentNames } from '../../../Interfaces/ModalInterface';
import GenericModal from '../../../views/Modals/GenericModal';
import { getFileAnswer, mimeMatch, updateAnswerDiagrams } from '../utils/DiagramUtils';
import { FileInterface } from '../../../Interfaces/DataPoint';
import MediaPreview from './MediaPreview';
import AudioRecorder from './extras/AudioRecorder';
import TranscribeButton from './extras/TranscribeBtn';

interface State {
  value: FileInterface[];
  accept: string;
  label: string;
  preview: boolean;
  previewUrl: string;
  previewType: string; // DIAGRAM || MEDIA
  selectedDiagram: string;
  disabled: boolean;
  audioDisabled: boolean;
  uploading: number[];
}

const ALL_FILES = ['.doc', '.docx', '.zip', '.htm', '.html', '.mhtlm', '.msg', '.pdf'];

export default class FileQuestion extends React.Component <FileQuestionPropsInterface, State> {
  private input = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);
    const { question, accept } = this.props;
    const stateAccept = question.type === questionTypes.PICTURES_QUESTION ?
      'image/*;capture=camera' : accept ? accept : '*/*, image/*;capture=camera, video/*;capture=camcorder';
    const value = this.getValue(stateAccept);
    // const hasAudio = value.find(f => f.mimeType.indexOf('audio') > -1);
    const disabled = question.allowedAttachments && value.length === question.allowedAttachments ? true : false;
    this.state = {
      value: value,
      label: this.getLabel(),
      accept: stateAccept.replace('application/pdf', ALL_FILES.join(',')),
      preview: false,
      previewUrl: '',
      selectedDiagram: '',
      previewType: '',
      disabled,
      audioDisabled: disabled,
      uploading: []
    };
    this.input = React.createRef();
  }

  @bind
  private getValue(stateAccept: string) {
    const { dataPoint, question, } = this.props;
    const files = dataPoint.files ? dataPoint.files : [];
    const answerFiles = files.filter(f => {
      if (f.questionId === '' || !f.questionId) {
        const isImage = f.mimeType.indexOf('image') === 0;
        if (isImage && question.id === 'Pictures') {
          return true;
        }/* else if (question.id === 'File') {
          return true;
        }*/
      } else if (f.questionId.toLowerCase().indexOf(question.id.toLowerCase()) === 0
        && mimeMatch(f.mimeType, stateAccept)) {
        return true;
      } else if (f.questionId.toLowerCase() === question.id.toLowerCase() && mimeMatch(f.mimeType, stateAccept)) {
        return true;
      } else if (f.questionId.toLowerCase() === question.id.toLowerCase()) {
        return true;
      }
      return false;
    });
    answerFiles.sort((a, b) => {
      if (isNullOrUndefined(a.index) && isNullOrUndefined(b.index)) {
        return 0;
      }
      if ((isNullOrUndefined(a.index) && !isNullOrUndefined(b.index)) ||
        (isNullOrUndefined(b.index) && !isNullOrUndefined(a.index)) ||
        // @ts-ignore
        a.index > b.index
      ) {
        return 1;
      }
      // @ts-ignore
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
    return answerFiles;
  }

  /*
    Returns the label to be used for the question
  */
  @bind
  private getLabel(): string {
    const { question, accept } = this.props;
    const mimeToText = (mime) => {
      const texts: string[] = [];
      if (mime.indexOf('pdf') !== -1) {
        texts.push(getLocalization('allFiles'));
      }
      if (mime.indexOf('image') !== -1) {
        texts.push(getLocalization('image'));
      }
      if (mime.indexOf('video') !== -1) {
        texts.push('Video');
      }
      if (mime.indexOf('audio') !== -1) {
        texts.push('Audio');
      }
      return texts.join(', ');
    };
    if (question.type === questionTypes.PICTURES_QUESTION) {
      return getLocalization('addimage');
    } else if (question.id === 'BackgroundDiagram') {
      return getLocalization('addBackgrounDiagram');
    } else if (question.id === 'File') {
      const acc = accept ? accept : '';
      return `${getLocalization('add')} ${mimeToText(acc)}`;
    } else if (question.id !== 'file' && accept) {
      return `${getLocalization('add')} ${mimeToText(accept)}`;
    } else {
      return '';
    }
  }

  @bind
  private handleChange(e) {
    const value = [...this.state.value];
    const uploading = [...this.state.uploading];
    const { question, dataPoint, clientPersist } = this.props;
    const { audioDisabled } = this.state;
    if (e.target.files && e.target.files.length > 0) {
      const files = [...e.target.files];
      if (question.allowedAttachments && (files.length + value.length) > question.allowedAttachments) {
        const msg = getLocalization('maxAttachment');
        alert(msg.replace('{{attachment}}', `${question.allowedAttachments}`));
        return;
      }
      e.target.value = '';
      const tid = Math.floor(Math.random() * 1000000);
      for (const f of files) {
        if (f.type.indexOf('image') === 0) {
          const fileItem: FileInterface  = {
            mimeType: f.type,
            file: f,
            fileSize: f.size,
            fileName: f.name,
            tid,
            questionId: question.id !== 'Pictures' ? question.id : undefined
          };
          const reader = new FileReader();
          reader.onload = (ev: any) => {
            fileItem.url = ev.target.result;
            value.push(fileItem);
            this.setState({
              value, disabled: this.isDisabled(question.allowedAttachments, value), uploading: uploading.concat([tid])
            });
            this.updateAnswer([fileItem]);
          };
          reader.readAsDataURL(f);
        } else {
          if (audioDisabled && f.type.indexOf('audio') > -1) {
            continue;
          }
          const fileItem: FileInterface = {
            mimeType: f.type,
            file: f,
            fileSize: f.size,
            fileName: f.name,
            tid,
            questionId: question.id !== 'Pictures' ? question.id : undefined,
            transcribeLanguage: f.type.indexOf('audio') > -1 ? clientPersist.transcriptionLang : null
          };
          value.push(fileItem);
          uploading.push(tid);
          const disabled = this.isDisabled(question.allowedAttachments, value);
          this.setState({
            value,
            disabled,
            uploading,
            audioDisabled: disabled // || ((question.convertToText && f.type.indexOf('audio') > -1) ? true : false)
          });
          this.updateAnswer([fileItem]);
          if (dataPoint[question.id] && question.convertToText) {
            alert(getLocalization('audioTranscribeAlert'));
          }
        }
      }
      // e.target.value = '';
      // this.setState({ value: this.state.value.concat(newFiles) });
    }
  }

  /*
    This function updates the main POI files item.
  */
  @bind
  private updateAnswer(fileItem: FileInterface[]) {
    const { dataPoint, updateAnswer, question } = this.props;
    let newAnswer = dataPoint['files'] ? [...dataPoint['files']] : [];
    if (question.id === 'BackgroundDiagram') {
      if (newAnswer.length > 0) {
        newAnswer = newAnswer.filter(f => f.questionId !== 'BackgroundDiagram');
      }
      updateAnswer({ files: newAnswer.concat(fileItem) });
      return;
    }

    if (this.input.current) {
      this.input.current.value = '';
    }
    this.props.getPresignedUrl({mimeType: fileItem[0].mimeType})
      .then(json => {
        if (json.payload && json.payload.id) {
          uploadToS3(fileItem[0].file, json.payload.url).then(() => {
            const file = {...fileItem[0]};
            file.id = json.payload.id;
            file.url = json.payload.accessUrl;
            delete file.file;
            updateAnswer({ files: newAnswer.concat(file) });
            this.updateUploading(fileItem[0].tid, file);
            console.log('uploaded file');
          }).catch(() => {
            console.log('error uploading file');
            updateAnswer({ files: newAnswer.concat(fileItem) });
            this.updateUploading(fileItem[0].tid);
          });
        } else {
          updateAnswer({ files: newAnswer.concat(fileItem) });
          this.updateUploading(fileItem[0].tid);
        }
        console.log(json);
      }).catch(e => {
        console.log(e);
        updateAnswer({ files: newAnswer.concat(fileItem) });
        this.updateUploading(fileItem[0].tid);
      });
  }

  @bind
  private updateUploading(tid?: number, file?: FileInterface) {
    if (tid) {
      if (file) {
        const newValue = this.state.value.map(f => {
          if (f.tid === tid) {
            const newFile = {...f, ...file};
            delete newFile.file;
            return newFile;
          }
          return f;
        });
        this.setState({uploading : this.state.uploading.filter(u => u !== tid), value: newValue});
      } else {
        this.setState({uploading : this.state.uploading.filter(u => u !== tid)});
      }
    }
  }

  @bind
  private onFileBtnClick() {
    if (this.input.current) {
      this.input.current.click();
    }
  }

  @bind
  private isDisabled(allowedAttachments: number | undefined, value: LooseObject[]) {
    if (allowedAttachments && value.length >= allowedAttachments) {
      return true;
    }
    return false;
  }

  @bind
  private removeFile(i: number) {
    const value: FileInterface[] = Object.assign([], this.state.value);
    const fileItem = value[i];
    value.splice(i, 1);
    const { dataPoint, updateAnswer, question } = this.props;
    const disabled = this.isDisabled(question.allowedAttachments, value);
    this.setState({ value, disabled, audioDisabled: disabled });
    const files =  dataPoint.files && dataPoint['files'].filter( f => {
      return (f.tid && f.tid !== fileItem.tid) || (f.id && f.id !== fileItem.id);
    });
    updateAnswer({ files: files });
  }

  /* Removes an image from the list. */
  @bind
  private confirmRemoveFile(i: number) {
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          this.removeFile(i);
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('yes'),
          confirmStyle: 'danger',
          header: (
            <p>
              {getLocalization('confirm')}
            </p>
          ),
          body: (
            <p>
              {getLocalization('confirmFileDelete')}
            </p>
          )
        }
      }
    });
  }

  @bind
  private onDragEnd(fromIndex: number, toIndex: number) {
    const value = [...this.state.value];
    const item = value.splice(fromIndex, 1)[0];
    value.splice(toIndex, 0, item);
    value.forEach((v, i) => {
      v['index'] = i;
    });
    const { dataPoint, updateAnswer } = this.props;
    const newFiles = [...value];
    const files =  dataPoint.files && dataPoint['files'].map( file => {
      for (let i = 0; i < newFiles.length; i++) {
        const f = newFiles[i];
        if ((f.tid && f.tid === file.tid) || (f.id && f.id === file.id)) {
          newFiles.splice(i, 1);
          return f;
        }
      }
      return file;
    });
    this.setState({ value }, () => updateAnswer({ files: files }));
  }

  @bind
  private previewMedia(view, url, type) {
    this.setState({ preview: true, previewUrl: url, previewType: type });
  }

  @bind
  private updateDataPoint(text: string, fileId: string, index: number) {
    const { dataPoint, question } = this.props;
    const newValue = {};
    newValue[question.id] = dataPoint[question.id] ? `${dataPoint[question.id]} ${text}` : text;
    if (fileId) {
      const value = this.state.value.map((v, i) => {
        if (i === index) {
          return {...v, id: fileId};
        }
        return v;
      });
      const fileItem = value[index];
      const files =  dataPoint.files && dataPoint['files'].map( f => {
        if (f.tid && f.tid === fileItem.tid) {
          const newFile = {...f, id: fileId};
          delete newFile['file'];
          return newFile;
        }
        return f;
      });
      newValue['files'] = files;
      this.setState({ value });
    }
    this.props.updateAnswer(newValue);
  }
  /*
    Renders the files.
  */
  @bind
  private getFiles(): JSX.Element {
    const { question } = this.props;
    const { value, uploading } = this.state;
    const signature = question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION;
    const { userName, pwrd, open } = globalWindow;

    const getImageUrl = (f) => {
      const refresh = f.rotated ? `&refresh=${Date.now()}` : '';
      const src = f.rotated ? `/json/file/download/${f.id}?size=small&userid=${userName}&key=${pwrd}${refresh}` :
        f.urlThumbnail && !signature ? f.urlThumbnail : f.url;
      return src;
    };

    const getUploadingDiv = (tid?: number) => {
      return tid && uploading.includes(tid) ? (
        <span>{getLocalization('uploadingfile')}...</span>
      ) : null;
    };

    const files = value.map((f, i) => {
      let view;
      let showPreview = false;
      let type;
      if (f.mimeType.indexOf('image') === 0) {
        view = (
          <img
            src={getImageUrl(f)}
            height={'200'}
            width={'100%'}
            key={`file-${Date.now()}`}
          />
        );
        showPreview = f.id ? true : false;
        type = 'IMAGE';
      } else if (f.mimeType.indexOf('video') === 0 ) {
        view = (
          <video src={f.url} controls className="video thumbnail">
            Your browser does not support the <code>video</code> element.
          </video>
        );
        showPreview = f.id ? true : false;
        type = 'VIDEO';
      } else if (f.mimeType.indexOf('audio') === 0) {
        view = (
          <audio src={f.url} controls>
            Your browser does not support the <code>audio</code> element.
          </audio>
        );
        type = 'AUDIO';
      } else if (f.mimeType.indexOf('*/*') !== -1) {
        view = (
          <React.Fragment>
            <img src="/res/images/pdf_icon.png" height="170" />
            <p>{f.name || f.fileName}</p>
          </React.Fragment>
        );
        type = 'FILE';
      } else {
        view = (
          <p>{f.name || f.fileName}</p>
        );
      }
      if (question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION) {
        return (
          <div key={`file_ds_${question.id}${f.tid ? f.tid : ''}`}>
            {view}
          </div>
        );
      }

      const rotateBtns = f.mimeType.indexOf('image') === 0 && f.id ? (
        <React.Fragment>
          <button
            className="btn btn-default btn-sm action-btn rotate-right"
            onClick={() => this.rotateImage('90', f.id)}
          >
            <i className="fa fa-rotate-right" />
          </button>
          <button
            className="btn btn-default btn-sm action-btn rotate-left"
            onClick={() => this.rotateImage('-90', f.id)}
          >
            <i className="fa fa-rotate-left" />
          </button>
        </React.Fragment>
      ) : null;

      const downloadBtn = f.id && f.url ? (
        <React.Fragment>
          <button
            className="btn btn-default btn-sm action-btn rotate-right"
            onClick={() => open(`/json/file/download/${f.id}?userid=${userName}&key=${pwrd}`, '_blank')}
          >
            <i className="fa fa-download" />
          </button>
        </React.Fragment>
      ) : null;
      const onPreviewClick = showPreview ? (
        () => this.previewMedia(view, f.url, type)
      ) : undefined;
      return (
        <div className="saved-file" key={`file_${question.id}_${i}${f.tid ? f.tid : ''}`}>
          <div className={`${type !== 'AUDIO' ? 'thumbnail' : 'audio-container'} pointer`} onClick={onPreviewClick}>
            {view}
          </div>
          {(
            <div>
              {rotateBtns}
              {downloadBtn}
              <button className="btn btn-danger btn-sm action-btn remove-btn" onClick={() => this.confirmRemoveFile(i)}>
                <span className="fa fa-trash" />
              </button>
              {type === 'AUDIO' && question.convertToText && (
                <TranscribeButton
                  file={f}
                  updateDataPoint={(text: string, fileId: string) => {
                    this.updateDataPoint(text, fileId, i);
                  }}
                />
              )}
              {getUploadingDiv(f.tid)}
            </div>
          )}
        </div>
      );
    });
    return (
      <ReactDragListView
        onDragEnd={this.onDragEnd}
        nodeSelector={'div.saved-file'}
        handleSelector={'div.saved-file'}
      >
        {files}
      </ReactDragListView>
    );
  }

  /*
    When a diagram is selected, we need to show a preview for the user to confirm they want to use the diagram.
  */
  @bind
  private handleDiagramSelect(diagram) {
    this.setState({ selectedDiagram: `${diagram.value}` });
    if (diagram.value === 'globalMap') {
      this.updateAnswerDiagrams([]);
    } else {
      this.props.getFileUrl(`${diagram.value}`, this.diagramUrlLoaded);
    }
  }

  /*
    Callback function when a diagram image url has been loaded.
    This function sets initializes the rendering of the image for preview and confirmation.
  */
  @bind
  private diagramUrlLoaded(url: string) {
    this.setState({ preview: true, previewUrl: url, previewType: 'DIAGRAM' });
  }

  /*
    Rotates an image
  */
  @bind
  private rotateImage(angle: string, fileId: number) {
    const { rotateImage } = this.props;
    rotateImage(angle, fileId, this.imageRotated);
  }

  @bind
  private imageRotated(fileId: number) {
    const newValues = this.state.value.map((f) => {
      if (f.id === fileId) {
        if (f.urlThumbnail) {
          f.urlThumbnail = `${f.urlThumbnail}#refresh=${Date.now()}`;
          f.rotated = true;
        } else {
          f.url = `${f.url}#refresh=${Date.now()}`;
        }
        return {...f};
      }
      return f;
    });
    this.setState({ value: newValues });
  }

  private getMediaPreview() {
    return (
      <MediaPreview url={this.state.previewUrl} type={this.state.previewType} />
    );
  }

  @bind
  private getDiagramPreviewContent() {
    const { previewUrl } = this.state;
    return (
      <div>
        <img className="diagram-img" src={`${previewUrl.replace(/"/g, '')}`} />
        <div>Use this diagram</div>
      </div>
    );
  }

  @bind
  private onConfirmDiagram() {
    const { groupDiagrams } = this.props;
    const { selectedDiagram } = this.state;
    const files = getFileAnswer(groupDiagrams, selectedDiagram);
    if (files) {
      this.updateAnswerDiagrams(files);
    }
    this.closeModals(false);
  }

  @bind
  private updateAnswerDiagrams(diagrams: FileInterface[]) {
    const { dataPoint, updateAnswer } = this.props;
    const newAnswer = updateAnswerDiagrams(dataPoint, diagrams);
    updateAnswer({ files: newAnswer });
    if (this.input.current) {
      this.input.current.value = '';
    }
  }

  @bind
  private closeModals(resetDiagram = true) {
    const newState = {...this.state};
    if (resetDiagram) {
      newState.selectedDiagram = '';
    }
    newState.preview = false;
    this.setState(newState);
  }

  /*
    Renders the view for selecting diagrams.
  */
  @bind
  private renderBackgroundDiagram() {
    const { label } = this.state;
    const { groupDiagrams, formUtils } = this.props;
    const options = groupDiagrams.map(diagram => {
      if (diagram.type === 'diagram') {
        return { name: diagram.name || diagram.filename, value: diagram.imageFileId };
      }
      return null;
    });
    let selectLabel = '';
    if (formUtils) {
      if (formUtils.getModel().isChild) {
        selectLabel = getLocalization('selectDiagramSubform');
      } else {
        selectLabel = getLocalization('selectDiagram');
      }
    }
    return (
      <div className="row background-diagram">
        <div className="col-md-6 background-diagram-select">
          <label>
            {selectLabel}
          </label>
          <Select
            key={`background-diagram-select`}
            labelField={'name'}
            valueField={'value'}
            create={false}
            values={[this.state.selectedDiagram]}
            multi={false} // { title: 'Select one', key: 'NULL' }
            // @ts-ignore
            options={[{name: 'Global Map', value: 'globalMap'}].concat(options.filter(o => o !== null))}
            // @ts-ignore
            onChange={(v) => v.length > 0 ? this.handleDiagramSelect(v[0]) : null}
            searchable
            searchBy={'name'}
          />
        </div>
        {
          formUtils && !formUtils.getModel().isChild ? (
            <div className="col-md-6 d-flex align-items-center">
              <button className="btn btn-primary" onClick={this.onFileBtnClick}>
                {label}
              </button>
            </div>
          ) : null
        }
      </div>
    );
  }

  /*
    Renders the button for selecting a file.
    For Digital Signature Questions, no file selection can be done in DV.
  */
  @bind
  private renderFileSelect(): JSX.Element | null {
    const { question, accept, dataPoint, clientPersist } = this.props;
    if (question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION) {
      return null;
    }
    const { label, audioDisabled, disabled, value } = this.state;
    const mandatory = ((
      question.type === questionTypes.FILE_QUESTION
      || question.type === questionTypes.PICTURES_QUESTION
    ) && question.optional === false) || question.fileMandatory === true;
    const audioRecorder = accept?.indexOf('audio') > -1 ? (
      <AudioRecorder
        disabled={audioDisabled}
        setData={(data: Blob) => {
          const fileItem: FileInterface = {
            mimeType: data.type || 'audio/*',
            file: data,
            fileSize: data.size,
            fileName: `audio-recorded`,
            tid: Math.floor(Math.random() * 1000000),
            questionId: question.id !== 'Pictures' ? question.id : undefined,
            url: URL.createObjectURL(data),
            transcribeLanguage: clientPersist.transcriptionLang
          };
          value.push(fileItem);
          const disabled = this.isDisabled(question.allowedAttachments, value);
          this.setState({
            value,
            disabled,
            audioDisabled: disabled
          });
          this.updateAnswer([fileItem]);
          if (dataPoint[question.id] && question.convertToText) {
            alert(getLocalization('audioTranscribeAlert'));
          }
        }}
      />
    ) : null;
    return !disabled ? (
      <>
        <div className="form-group new-file mt-2">
          <button
            className="btn btn-primary btn-sm"
            onClick={this.onFileBtnClick}
            disabled={disabled}
          >
            {label}
            {mandatory && (
              <span className="required">{` * `}</span>
            )}
          </button>
        </div>
        {audioRecorder}
      </>
    ) : null;
  }

  /* public shouldComponentUpdate(nextProps, nextState) {
    return this.state.preview !== nextState.preview ||
      this.state.selectedDiagram !== nextState.selectedDiagram;
  }*/

  public render(): JSX.Element {
    const { accept, preview, previewType } = this.state;
    const { question, edit } = this.props;
    const diagramPreview = previewType === 'DIAGRAM' ? true : false;
    const previewModal = preview ? (
      <GenericModal
        visible={this.state.preview}
        body={diagramPreview ? this.getDiagramPreviewContent() : this.getMediaPreview()}
        title={'Preview'}
        onConfirm={diagramPreview ? this.onConfirmDiagram : this.closeModals}
        cancel={diagramPreview ? this.closeModals : undefined}
        cancelText={'Cancel'}
        confirmText={'OK'}
        dialogClassName={'large-modal'}
      />
    ) : null;
    return (
      <div className="container-fluid">
        {previewModal}
        {edit ? question.id === 'BackgroundDiagram' ? this.renderBackgroundDiagram() : this.renderFileSelect() : null}
        <input
          type="file"
          name={question.id}
          className="form-control d-none"
          ref={this.input}
          accept={accept}
          onChange={this.handleChange}
          multiple={question.id !== 'BackgroundDiagram'}
        />
        <div className="row">
          {question.id === 'BackgroundDiagram' ? null : this.getFiles()}
        </div>
      </div>
    );
  }
}
