import * as React from 'react';
import { useSelector } from 'react-redux';
import ReactGridLayout from "react-grid-layout";
import { ProgressBar } from 'react-bootstrap';
import { ChartModel, ChartResponse } from 'Interfaces/ChartInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import ChartBoxComponent from 'views/Charts/ChartBoxComponent';
import { useAppDispatch } from 'index';
import { getLocalization } from 'global/global';
import { removeChartFromDashboard } from 'actions/dashboardActions';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import { getRequestConfig } from '../../../api';
import * as ChartUtils from '../../Charts/utils';

interface Props {
  chart: ChartModel;
  dashboardId: number;
  canEdit: boolean;
  applyFilters: boolean;
  layout: ReactGridLayout.Layout[];
}

export default function DashboardChart(props: Props) {
  const dispatch = useAppDispatch();
  const forms = useSelector((state: StateInterface) => state.forms);
  const locations = useSelector((state: StateInterface) => state.locations);
  const filtersMenu = useSelector((state: StateInterface) => state.filtersMenu);
  const [chartResponse, setChartResponse] = React.useState<ChartResponse[]>([]);
  const [charts, setCharts] = React.useState<JSONInterface[]>([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (chartResponse.length > 0) {
      const charts = ChartUtils.createCharts(
        props.chart, chartResponse, undefined, forms.collection, locations.collection, {}
      );
      setCharts(charts);
    } else {
      setCharts([]);
    }
  }, [chartResponse]);

  React.useEffect(() => {
    setCharts(charts.map(c => { return {...c}; }));
  }, [props.layout]);

  React.useEffect(() => {
    if (!loading) {
      const headers = getRequestConfig();
      const model = {...props.chart};
      if (props.applyFilters) {
        const locations: string[] = [];
        const levels: string[] = [];
        filtersMenu.selectedLocations.forEach(location => {
          locations.push(`${location.key}`);
          levels.push(`${Number(location.level) + 1}`);
        });
        model.locations = locations.join(',');
        model.locationLevels = levels.join(',');
        model.fromDate = filtersMenu.selectedDates.from || '';
        model.toDate = filtersMenu.selectedDates.to || '';
        model.users = filtersMenu.selectedUsers.map(u => u.id).join(',');
      }
      setLoading(true);
      fetch(
        `/json/chart?chartModel=${encodeURIComponent(JSON.stringify(model))}`, headers
      ).then(res => res.json()).then(json => {
        setLoading(false);
        setChartResponse(json);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [props.chart, props.applyFilters, filtersMenu]);

  const switchChart = (id: string, action: boolean, name?: string) => {
    if (name?.startsWith('sort')) {
      const chart = chartResponse.find((cd) => cd.id === id);
      if (chart && chart.series.length > 0) {
        const newChart = ChartUtils.getBarChart(props.chart, chart, undefined, locations.collection, action, name);
        const newCharts = charts.map(ch => {
          if (ch.responseId !== id) {
            return ch;
          }
          return newChart[0];
        });
        setCharts(newCharts);
      }
    } else if (name === 'cummulative') {
      const { chart: model } = props;
      if (model.combine === 'FIELD') {
        const charts = ChartUtils.getCombinedLineChart(model, chartResponse, action, locations.collection);
        setCharts(charts);
      } else if (model.combine === 'LOCATION') {
        const charts = ChartUtils.getCombinedLocationLineChart(
          model, chartResponse, forms.collection, action, locations.collection
        );
        setCharts(charts);
      } else {
        const chart = chartResponse.find((cd) => cd.id === id);
        if (chart) {
          const newChart = ChartUtils.getLineChart(model, chart, forms.collection, action);
          const newCharts = charts.map((ch) => {
            if (ch.responseId === id) {
              return newChart;
            }
            return ch;
          });
          setCharts(newCharts);
        }
      }
      return;
    }
  };

  const onRemoveChart = () => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onConfirm: () => {
          void dispatch(removeChartFromDashboard({
            dashboardId: props.dashboardId, chartId: Number(props.chart.id)
          }));
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('removeChart')}</p>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  return (
    <>
      {loading && (
        <div className="col dashboard-chart-container">
          <ProgressBar striped={true} variant={'primary'} animated={true} now={100} />
        </div>
      )}
      {charts.length > 0 ? charts.map((chart, index) => (
        <ChartBoxComponent
          key={`${props.chart.id}-chart-${chart['id']}-${index}-${chart['responseId']}`}
          id={chart['responseId']}
          index={index}
          chartType={props.chart.type}
          options={chart}
          switchChart={switchChart}
          addChartSvgs={() => null}
          model={props.chart}
          dashboard={true}
          removeChart={props.canEdit ? onRemoveChart : undefined}
        />
      )) : null}
    </>
  );
}
